<!-- 评价管理-->
<template>
  <div>
    <div style="margin-bottom: 5px">
      <el-input
        v-model="name"
        placeholder="请输入店铺名"
        suffix-icon="el-icon-search"
        style="width: 200px"
        @keyup.enter.native="loadPost"
      ></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="loadPost"
        >查询</el-button
      >
      <el-button type="success" @click="resetParam">重置</el-button>
      <el-button type="primary" style="margin-left: 5px" @click="add"
        >新增</el-button
      >
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{ background: '#f2f5fc', color: '#555555' }"
      border
    >
      <el-table-column prop="id" label="ID" width="60"> </el-table-column>
      <el-table-column prop="no" label="账号" width="180"> </el-table-column>
      <el-table-column prop="password" label="密码" width="180">
      </el-table-column>
      <el-table-column prop="s_name" label="店铺名" width="180">
      </el-table-column>
      <el-table-column prop="u_name" label="用户名" width="180">
      </el-table-column>
      <el-table-column prop="operate" label="操作">
        <template slot-scope="scope">
          <el-button
            slot="reference"
            size="small"
            type="success"
            @click="mod(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="del(scope.row.id)"
            style="margin-left: 5px"
          >
            <el-button slot="reference" size="small" type="danger"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 20, 30]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="账号" prop="no">
          <el-col :span="20">
            <el-input v-model="form.no"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-col :span="20">
            <el-input v-model="form.password"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="店铺名" prop="s_id">
          <el-select v-model="form.s_id" placeholder="请选择店铺">
            <el-option
              v-for="item in shops"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户名" prop="s_id">
          <el-select v-model="form.u_id" placeholder="请选择用户">
            <el-option
              v-for="item in users"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "StorageManage",
  data() {
    return {
      role: "",
      tableData: [
        // {
        //   id: 1,
        //   name: "王志龙",
        //   image: "1677831111714.png",
        //   d_id: 2,
        //   d_name: "好杀好杀",
        // },
      ],
      dialogFormVisible: false,
      dialogVisible: false,
      pageSize: 10,
      pageNum: 1,
      total: 0,
      name: "",
      centerDialogVisible: false,
      form: {
        id: "",
        no: "",
        password: "",
        role: "",
        s_id: "",
        s_name: "",
        u_id: "",
        u_name: "",
      },
      shops: [],
      users: [],
      rep: {
        context: "",
      },
      rules: {
        no: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        s_id: [{ required: true, message: "请选择店铺", trigger: "blur" }],
      },
    };
  },
  methods: {
    add() {
      this.centerDialogVisible = true;
      this.$nextTick(() => {
        this.form = {};
      });
    },
    save() {
      if (this.form.no == undefined || this.form.password == undefined) {
        this.$message({
          message: "请正确输入！",
          type: "error",
        });
        return;
      }
      this.form.role = 1;
      // 过滤出当前 id 并赋值
      this.form.s_name = this.shops.filter((x) => {
        return x.id == this.form.s_id;
      })[0].title;
      this.form.u_name = this.users.filter((x) => {
        return x.id == this.form.u_id;
      })[0].name;
      console.log(this.form);
      // 请求添加
      this.$axios
        .post(this.$httpUrl + "/admin/addUpdate", this.form)
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.loadPost();
            this.$message({
              message: "操作成功！",
              type: "success",
            });
          } else {
            this.$message({
              message: "账号重复请重试！",
              type: "error",
            });
          }
        });
      this.centerDialogVisible = false;
    },
    // 编辑商品
    mod(row) {
      this.centerDialogVisible = true;
      this.form.id = row.id;
      this.form.no = row.no;
      this.form.password = row.password;
      this.form.role = row.role;
      this.form.s_id = row.s_id;
      this.form.s_name = row.s_name;
      this.form.u_id = row.u_id;
      this.form.u_name = row.u_name;
    },
    del(id) {
      this.$axios
        .get(this.$httpUrl + "/admin/remove/" + id)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadPost();
          } else {
            this.$message({
              message: "删除失败！",
              type: "error",
            });
          }
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageNum = 1;
      this.pageSize = val;
      this.loadPost();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.loadPost();
    },
    resetParam() {
      this.name = "";
    },
    loadPost() {
      this.tableData = [];
      this.$axios
        .post(this.$httpUrl + "/admin/pagelist", {
          id: this.$store.state.menu.id,
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          param: {
            s_name: this.name,
          },
          role: this.$store.state.menu.role,
        })
        .then((res) => res.data)
        .then((res) => {
          this.tableData = res.data;
          console.log(res.data);
        });
    },
    loadshops() {
      this.$axios
        .get(this.$httpUrl + "/shop/getShopList")
        .then((res) => res.data)
        .then((res) => {
          console.log("shops:");
          console.log(res.data);
          this.shops = res.data;
        });
    },
    loadusers() {
      this.$axios
        .get(this.$httpUrl + "/user/getuserList")
        .then((res) => res.data)
        .then((res) => {
          console.log("users:");
          console.log(res.data);
          this.users = res.data;
        });
    },
  },
  beforeMount() {
    this.loadPost();
    this.loadshops();
    this.loadusers();
  },
};
</script>

<style scoped>
</style>